<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-2"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="BookIcon"
              size="18"
            />
            <span class="align-middle ml-50">{{ $route.params.serviceType.toUpperCase() }}</span>
          </b-card-title>
        </b-card-header>
        <validation-observer ref="formData">
          <b-form class="p-2">
            <b-row>
              <b-col cols="6">
                <b-form-group
                  :label="$t('Image')"
                  label-for="register-image"
                >
                  <!-- media -->
                  <b-media no-body>
                    <b-media-aside>
                      <b-link>
                        <b-img
                          v-if="image"
                          ref="previewEl"
                          rounded
                          :src="image"
                          height="80"
                        />
                        <b-img
                          v-else
                          ref="previewEl"
                          :src="require('@/assets/images/blank/no_image.png')"
                          rounded
                          height="80"
                        />
                      </b-link>
                    <!--/ avatar -->
                    </b-media-aside>

                    <b-media-body class="mt-75 ml-75">
                      <!-- upload button -->
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        size="sm"
                        class="mb-75 mr-75"
                        @click="$refs.refInputImage.$el.click()"
                      >
                        {{ $t("Upload") }}
                      </b-button>
                      <b-form-file
                        ref="refInputImage"
                        accept=".webp, .jpeg, .jpg, .png, .gif"
                        :hidden="true"
                        plain
                        @change="inputImageRenderer"
                      />
                      <!--/ upload button -->

                      <!-- reset -->
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        size="sm"
                        class="mb-75 mr-75"
                        @click="resetImage"
                      >
                        {{ $t("Reset") }}
                      </b-button>
                      <!--/ reset -->
                      <b-card-text>{{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG. {{ $t('Max size of') }} 300KB</b-card-text>
                    </b-media-body>
                  </b-media>
                <!--/ media -->
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group>
                  <b-card-text>{{ $t("Cover") }} {{ $t("All") }}
                    {{ cover.length }} {{ $t("Cover") }}</b-card-text>
                  <!-- media -->
                  <b-media no-body>
                    <b-media-aside>
                      <b-row>
                        <b-col
                          v-for="(item, index) in cover"
                          :key="index"
                          :cols="cover.length < 4?'12':'3'"
                        >
                          <b-img
                            fluid-grow
                            :src="item"
                            rounded
                            class="mr-1 mb-1 imgs-show"
                            height="120"
                          />
                          <b-button
                            variant="danger"
                            class="btn-remove"
                            @click="removeCover(index)"
                          >
                            {{ $t("Delete") }} ({{ index+1 }})
                          </b-button>
                        </b-col>
                      </b-row>

                    <!--/ avatar -->
                    </b-media-aside>
                    <b-media-body class="mt-75 ml-75">
                      <!-- upload button -->
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        size="sm"
                        class="mb-75 mr-75"
                        @click="$refs.refInputCover.$el.click()"
                      >
                        {{ $t("Upload") }}
                      </b-button>
                      <b-form-file
                        ref="refInputCover"
                        accept=".webp, .jpeg, .jpg, .png, .gif"
                        :hidden="true"
                        plain
                        @change="inputCoverRenderer"
                      />
                      <!--/ upload button -->

                      <!-- reset -->
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        size="sm"
                        class="mb-75 mr-75"
                        @click="resetCover"
                      >
                        {{ $t("Reset") }}
                      </b-button>
                      <!--/ reset -->
                      <b-card-text>{{ $t("Allowed") }} JPG, GIF {{ $t("or") }} PNG.
                        {{ $t("Max size of") }} 300KB</b-card-text>
                    </b-media-body>
                  </b-media>
                <!--/ media -->
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <!-- refId -->
                <b-form-group
                  :label="$t('First Name')"
                  label-for="register-firstName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="firstName"
                    vid="firstName"
                    rules="required"
                  >
                    <b-form-input
                      id="register-firstName"
                      v-model="firstName"
                      name="register-firstName"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('First Name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <!-- refId -->
                <b-form-group
                  :label="$t('Last Name')"
                  label-for="register-lastName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="lastName"
                    vid="lastName"
                    rules=""
                  >
                    <b-form-input
                      id="register-lastName"
                      v-model="lastName"
                      name="register-lastName"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Last Name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  :label="$t('Gender')"
                  label-for="register-gender"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="gender"
                    vid="gender"
                    rules="required"
                  >
                    <v-select
                      id="register-gender"
                      v-model="gender"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="OptionGender"
                      :reduce="(OptionGender) => OptionGender.value"
                      class="select-size-lg"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider></b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  :label="$t('System Options')"
                  label-for="register-systemOptions"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="systemOptions"
                    vid="systemOptions"
                    rules="required"
                  >
                    <v-select
                      id="register-systemOptions"
                      v-model="systemOptions"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="OptionSystemOptions"
                      :reduce="(OptionSystemOptions) => OptionSystemOptions.value"
                      class="select-size-lg"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider></b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  :label="$t('Work Behavior')"
                  label-for="register-workBehavior"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="workBehavior"
                    vid="workBehavior"
                    rules="required"
                  >
                    <v-select
                      id="register-workBehavior"
                      v-model="workBehavior"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="OptionWorkBehavior"
                      :reduce="(OptionWorkBehavior) => OptionWorkBehavior.value"
                      class="select-size-lg"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider></b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group
                  :label="$t('Service Area')"
                  label-for="register-serviceAreaId"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="serviceAreaId"
                    vid="serviceAreaId"
                    rules=""
                  >
                    <v-select
                      id="register-serviceAreaId"
                      v-model="serviceAreaId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="serviceAreaData"
                      :reduce="(serviceAreaData) => serviceAreaData._id"
                      class="select-size-lg"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider></b-form-group>
              </b-col>
              <b-col
                v-if="partnerTemplateTimeData.length>0"
                cols="6"
              >
                <b-form-group
                  :label="$t('Template Time')"
                  label-for="register-partnerTemplateTime"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="partnerTemplateTime"
                    vid="partnerTemplateTime"
                    rules=""
                  >
                    <v-select
                      id="register-partnerTemplateTime"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="partnerTemplateTimeData"
                      :reduce="(partnerTemplateTimeData) => partnerTemplateTimeData.workingPeriod"
                      class="select-size-lg"
                      @input="templateTime($event)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider></b-form-group>
              </b-col>
              <b-col
                v-if="servicePackageData.length>0"
                cols="6"
              >
                <b-form-group
                  :label="$t('Package')"
                  label-for="register-package"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="package"
                    vid="package"
                    rules=""
                  >
                    <v-select
                      id="register-package"
                      v-model="servicePackageId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="servicePackageData"
                      :reduce="(servicePackageData) => servicePackageData._id"
                      class="select-size-lg"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider></b-form-group>
              </b-col>
              <!-- <b-col
                cols="12"
              >
                <hr>
              </b-col>
              <b-col cols="12">
                <b-card-text>{{ $t("Search option") }}</b-card-text>
              </b-col>
              <b-col
                v-for="(item,index) in maxSearchOption"
                :key="`SearchOption_${index}`"
                cols="6"
              >
                <b-form-group
                  :label="searchOptionLabel[index] ? searchOptionLabel[index].name: `${$t('Category')} ${index+1}`"
                  label-for="register-search-option"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="search-option"
                    vid="search-option"
                    rules=""
                  >
                    <v-select
                      id="register-search-option"
                      v-model="selectedOption[index]"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="filterPosition(index)"
                      :reduce="(searchOption) => searchOption._id"
                      class="select-size-lg"
                      @input="onSelectOption(index,$event)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider></b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <hr>
              </b-col>
              <b-col cols="12">
                <b-card-text>{{ $t("List search option") }}</b-card-text>
              </b-col>
              <b-col
                v-for="(item,index) in searchOptionList"
                :key="index"
                cols="6"
              >
                <b-form-group
                  :label="searchOptionLabel[index] ? searchOptionLabel[index].name: `${$t('Category')} ${index+1}`"
                  label-for="register-package"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="package"
                    vid="package"
                    rules=""
                  >
                    <v-select
                      id="register-package"
                      v-model="selectedListOption[item._id]"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="item.list"
                      :reduce="(dataListSearchOption) => dataListSearchOption._id"
                      class="select-size-lg"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider></b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <hr>
              </b-col> -->
              <b-col
                cols="12"
              >
                <!-- refId -->
                <b-form-group
                  :label="$t('Working Period')"
                  label-for="register-workingPeriod"
                >
                  <b-table
                    striped
                    responsive
                    class="mb-0"
                    :items="workingPeriod"
                    :fields="tableWorkingPeriodColumns"
                    style="height: 60vh;"
                  >
                    <template #cell(day)="data">
                      <p class="align-middle">
                        {{ $t(workingPeriod[data.index].day) }}
                      </p>
                      <p
                        v-if="workingPeriod[data.index].openTime.hour !==null && workingPeriod[data.index].openTime.minute !==null
                          && workingPeriod[data.index].closeTime.hour !==null && workingPeriod[data.index].closeTime.minute !== null
                        "
                        class="align-middle"
                      >
                        {{ $t('Moment 1') }} : ({{ timeFomat(workingPeriod[data.index].openTime.hour) }}:{{ timeFomat(workingPeriod[data.index].openTime.minute) }}-{{ timeFomat(workingPeriod[data.index].closeTime.hour) }}:{{ timeFomat(workingPeriod[data.index].closeTime.minute) }})
                      </p>
                      <p
                        v-if="workingPeriod[data.index].openTime2.hour !==null && workingPeriod[data.index].openTime2.minute !==null
                          && workingPeriod[data.index].closeTime2.hour !==null && workingPeriod[data.index].closeTime2.minute !== null
                        "
                        class="align-middle"
                      >
                        {{ $t('Moment 2') }} : ({{ timeFomat(workingPeriod[data.index].openTime2.hour) }}:{{ timeFomat(workingPeriod[data.index].openTime2.minute) }}-{{ timeFomat(workingPeriod[data.index].closeTime2.hour) }}:{{ timeFomat(workingPeriod[data.index].closeTime2.minute) }})
                      </p>
                    </template>
                    <template #cell(openTime)="data">
                      <b-row>
                        <b-col cols="6">
                          <validation-provider
                            #default="{ errors }"
                            name="Hour"
                            vid="hour"
                            rules="min:1|max:2|between:0,23"
                          >
                            <b-form-input
                              :id="'openHour'+data.index"
                              v-model="workingPeriod[data.index].openTime.hour"
                              name="hour"
                              :placeholder="$t('Hour')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <b-col cols="6">
                          <validation-provider
                            #default="{ errors }"
                            name="Minute"
                            vid="minute"
                            rules="min:1|max:2|between:0,59"
                          >
                            <b-form-input
                              :id="'openMinute'+data.index"
                              v-model="workingPeriod[data.index].openTime.minute"
                              name="minute"
                              :placeholder="$t('Minute')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                      </b-row>

                    </template>
                    <template #cell(closeTime)="data">
                      <b-row>
                        <b-col cols="6">
                          <validation-provider
                            #default="{ errors }"
                            name="Hour"
                            vid="hour"
                            rules="min:1|max:2|between:0,23"
                          >
                            <b-form-input
                              :id="'closeHour'+data.index"
                              v-model="workingPeriod[data.index].closeTime.hour"
                              name="hour"
                              :placeholder="$t('Hour')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <b-col cols="6">
                          <validation-provider
                            #default="{ errors }"
                            name="Minute"
                            vid="minute"
                            rules="min:1|max:2|between:0,59"
                          >
                            <b-form-input
                              :id="'closeMinute'+data.index"
                              v-model="workingPeriod[data.index].closeTime.minute"
                              name="minute"
                              :placeholder="$t('Minute')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                      </b-row>

                    </template>
                    <template #cell(openTime2)="data">
                      <b-row>
                        <b-col cols="6">
                          <validation-provider
                            #default="{ errors }"
                            name="Hour"
                            vid="hour"
                            rules="min:1|max:2|between:0,23"
                          >
                            <b-form-input
                              :id="'open2Hour'+data.index"
                              v-model="workingPeriod[data.index].openTime2.hour"
                              name="hour"
                              :placeholder="$t('Hour')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <b-col cols="6">
                          <validation-provider
                            #default="{ errors }"
                            name="Minute"
                            vid="minute"
                            rules="min:1|max:2|between:0,59"
                          >
                            <b-form-input
                              :id="'open2Minute'+data.index"
                              v-model="workingPeriod[data.index].openTime2.minute"
                              name="minute"
                              :placeholder="$t('Minute')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                      </b-row>

                    </template>
                    <template #cell(closeTime2)="data">
                      <b-row>
                        <b-col cols="6">
                          <validation-provider
                            #default="{ errors }"
                            name="Hour"
                            vid="hour"
                            rules="min:1|max:2|between:0,23"
                          >
                            <b-form-input
                              :id="'close2Hour'+data.index"
                              v-model="workingPeriod[data.index].closeTime2.hour"
                              name="hour"
                              :placeholder="$t('Hour')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <b-col cols="6">
                          <validation-provider
                            #default="{ errors }"
                            name="Minute"
                            vid="minute"
                            rules="min:1|max:2|between:0,59"
                          >
                            <b-form-input
                              :id="'close2Minute'+data.index"
                              v-model="workingPeriod[data.index].closeTime2.minute"
                              name="minute"
                              :placeholder="$t('Minute')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                      </b-row>

                    </template>
                    <template #cell(action)="data">
                      <b-form-checkbox
                        v-model="workingPeriod[data.index].status"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                      />
                    </template>
                  </b-table>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <!-- refId -->
                <b-form-group
                  :label="$t('Call Table')"
                  label-for="register-callTable"
                >
                  <b-form-checkbox
                    v-model="callTableStatus"
                    switch
                    inline
                  />
                  <b-table
                    v-if="callTableStatus"
                    striped
                    responsive
                    class="mb-0"
                    :items="callTable"
                    :fields="tableCallColumns"
                    style="height: 60vh;"
                  >
                    <template #cell(start)="data">
                      <!-- <b-form-input
                        :id="'start'+data.index"
                        v-model="callTable[data.index].start"
                        name="start"
                        :placeholder="$t('Start distance')"
                        :disabled="data.index === 0 ? true : false"
                      /> -->
                      <cleave
                        :id="'start'+data.index"
                        v-model="callTable[data.index].start"
                        class="form-control"
                        :raw="false"
                        :options="optionCleave.number"
                        :placeholder="$t('Start distance')"
                        :disabled="data.index === 0 ? true : false"
                      />
                      <small
                        v-if="checkStartDistance(callTable[data.index],data.index)"
                        class="text-danger"
                      >{{ $t('The end distance of the table above must be less than') }}</small>
                    </template>
                    <template #cell(end)="data">
                      <!-- <b-form-input
                        :id="'end'+data.index"
                        v-model="callTable[data.index].end"
                        name="end"
                        :placeholder="$t('End distance')"
                      /> -->
                      <cleave
                        :id="'end'+data.index"
                        v-model="callTable[data.index].end"
                        class="form-control"
                        :raw="false"
                        :options="optionCleave.number"
                        :placeholder="$t('End distance')"
                      />
                      <small
                        v-if="checkEndDistance(callTable[data.index],data.index)"
                        class="text-danger"
                      >{{ $t('The starting distance must be less than the ending distance') }}</small>
                    </template>
                    <template #cell(price)="data">
                      <!-- <b-form-input
                        :id="'price'+data.index"
                        v-model="callTable[data.index].price"
                        name="price"
                        :placeholder="$t('Price')"
                      /> -->
                      <cleave
                        :id="'price'+data.index"
                        v-model="callTable[data.index].price"
                        class="form-control"
                        :raw="false"
                        :options="optionCleave.number"
                        :placeholder="$t('Price')"
                      />
                      <small
                        v-if="callTable[data.index].price === '' || callTable[data.index].price === null"
                        class="text-danger"
                      >{{ $t('Please enter price') }}</small>
                    </template>
                    <template #cell(action)="data">
                      <b-row>
                        <b-button
                          v-if="data.index === callTable.length-1"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="d-flex"
                          @click="addCallTable(data.index)"
                        >
                          <feather-icon
                            icon="PlusIcon"
                          />
                        </b-button>
                        <b-button
                          v-if="data.index !== 0"

                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="danger"
                          class="d-flex"
                          @click="deleteCallTable(data.index)"
                        >
                          <feather-icon
                            icon="TrashIcon"
                          />
                        </b-button>
                      </b-row>

                    </template>
                  </b-table>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <GmapMap
                  ref="mapRef"
                  :style="'height:500px'"
                  :center="dataCenter"
                  :zoom="zoom"
                  @click="drawInMap"
                >
                  <GmapMarker
                    :draggable="true"
                    :position="dataCenter"
                    :clickable="true"
                    @dragend="setLocal"
                  />
                </GmapMap>
              </b-col>
            </b-row>
            <!-- Action button -->
            <div class="d-flex mt-2">
              <b-button
                variant="primary"
                class="mr-2"
                @click="validationForm()"
              >
                {{ $t("Submit") }}
              </b-button>
              <b-button
                type="button"
                variant="outline-secondary"
                @click="cancel"
              >
                {{ $t("Cancel") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCardHeader,
  BCardTitle,
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BButton,
  BOverlay,
  BTable,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BCardText,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import storeModule from '@/store/services/hero/partnerType'
import storeModuleServiceArea from '@/store/services/hero/serviceArea'
import storeModulePartnerTemplateTime from '@/store/services/hero/partnerTemplateTime'
import storeModuleServicePackage from '@/store/services/hero/servicePackage'
import storeModuleSearchOption from '@/store/services/hero/searchOption'

const STORE_MODULE_NAME = 'partnerType'
const STORE_MODULE_NAME_SERVICE_AREA = 'serviceArea'
const STORE_MODULE_NAME_PARTNER_TEMPLATE_TIME = 'partnerTemplateTime'
const STORE_MODULE_NAME_SERVICE_PACKAGE = 'servicePackage'
const STORE_MODULE_NAME_SEARCH_OPTION = 'searchOption'

export default {
  components: {
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BCardHeader,
    BCardTitle,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BButton,
    BOverlay,
    BTable,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BCardText,
    vSelect,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      dataId: null,
      servicePackageId: '',
      serviceAreaId: '',
      image: '',
      cover: [],
      firstName: '',
      lastName: '',
      systemOptions: '',
      workBehavior: '',
      workingPeriod: [],
      callTableStatus: false,
      callTable: [
        {
          start: '0', // ระยะทางเริ่มต้น
          end: '', // ระยะสิ้นสุด
          price: '', // ราคา
        },
      ],
      partnerLocation: {
        type: 'Point',
        coordinates: [103.652653, 16.056734],
      },
      dataCenter: { lat: 16.056734, lng: 103.652653 },
      zoom: 12,
      gender: '',
      licensePlate: '',
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      selectedOption: {},
      selectedListOption: {},
      required,
    }
  },
  computed: {
    serviceAreaData() {
      return store.state[STORE_MODULE_NAME_SERVICE_AREA].respData != null ? store.state[STORE_MODULE_NAME_SERVICE_AREA].respData.data : []
    },
    partnerTemplateTimeData() {
      return store.state[STORE_MODULE_NAME_PARTNER_TEMPLATE_TIME].respData != null ? store.state[STORE_MODULE_NAME_PARTNER_TEMPLATE_TIME].respData.data : []
    },
    servicePackageData() {
      return store.state[STORE_MODULE_NAME_SERVICE_PACKAGE].respData != null ? store.state[STORE_MODULE_NAME_SERVICE_PACKAGE].respData.data : []
    },
    OptionSystemOptions() {
      return [
        { name: this.$t('Join as a partner'.toUpperCase()), value: 'join_as_a_partner' },
        { name: this.$t('Not joining as a partner'.toUpperCase()), value: 'not_joining_as_a_partner' },
      ]
    },
    OptionWorkBehavior() {
      return [
        { name: this.$t('Full Time'.toUpperCase()), value: 'full_time' },
        { name: this.$t('Part Time'.toUpperCase()), value: 'part_time' },
      ]
    },
    OptionGender() {
      return [
        { name: this.$t('None'.toUpperCase()), value: 'none' },
        { name: this.$t('Male'.toUpperCase()), value: 'male' },
        { name: this.$t('Female'.toUpperCase()), value: 'female' },
      ]
    },
    tableCallColumns() {
      return [
        { key: 'start', label: this.$t('Start distance'), sortable: false },
        { key: 'end', label: this.$t('End distance'), sortable: false },
        { key: 'price', label: this.$t('Price'), sortable: false },
        { key: 'action', label: this.$t('Action'), sortable: false },
      ]
    },
    tableWorkingPeriodColumns() {
      return [
        { key: 'day', label: this.$t('Day'), sortable: false },
        { key: 'openTime', label: this.$t('OpenTime'), sortable: false },
        { key: 'closeTime', label: this.$t('CloseTime'), sortable: false },
        { key: 'openTime2', label: this.$t('OpenTime2'), sortable: false },
        { key: 'closeTime2', label: this.$t('CloseTime2'), sortable: false },
        { key: 'action', label: this.$t('Action'), sortable: false },
      ]
    },
    options() {
      const dataOption = Object.keys(this.selectedOption)
      return { data: dataOption, length: dataOption.length }
    },
    searchOption() {
      return store.state[STORE_MODULE_NAME_SEARCH_OPTION].respData != null ? store.state[STORE_MODULE_NAME_SEARCH_OPTION].respData.data.map(e => {
        let name = ''
        const indexLang = e.name.findIndex(i => i.lang.toString() === this.$i18n.locale.toString())
        if (indexLang > -1) {
          name = e.name[indexLang].value
        }
        return {
          ...e,
          name,
        }
      }) : []
    },
    searchOptionLabel() {
      return store.state[STORE_MODULE_NAME_SEARCH_OPTION].respData != null ? store.state[STORE_MODULE_NAME_SEARCH_OPTION].respData.label : []
    },
    maxSearchOption() {
      if (this.searchOption.length === 0) {
        return 0
      }
      return this.searchOption.reduce((max, obj) => (obj.position > max.position ? obj : max), this.searchOption[0]).position
    },
    searchOptionList() {
      return store.state[STORE_MODULE_NAME_SEARCH_OPTION].respDataListOption != null ? store.state[STORE_MODULE_NAME_SEARCH_OPTION].respDataListOption.data.map(e => {
        const list = e.list.map(l => {
          let name = ''
          const indexLang = l.name.findIndex(i => i.lang.toString() === this.$i18n.locale.toString())
          if (indexLang > -1) {
            name = l.name[indexLang].value
          }
          return {
            ...l,
            name,
          }
        })
        return {
          ...e,
          list,
        }
      }) : []
    },
    // eslint-disable-next-line vue/return-in-computed-property
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    if (!store.hasModule(STORE_MODULE_NAME_SERVICE_AREA)) store.registerModule(STORE_MODULE_NAME_SERVICE_AREA, storeModuleServiceArea)
    if (!store.hasModule(STORE_MODULE_NAME_PARTNER_TEMPLATE_TIME)) store.registerModule(STORE_MODULE_NAME_PARTNER_TEMPLATE_TIME, storeModulePartnerTemplateTime)
    if (!store.hasModule(STORE_MODULE_NAME_SERVICE_PACKAGE)) store.registerModule(STORE_MODULE_NAME_SERVICE_PACKAGE, storeModuleServicePackage)
    if (!store.hasModule(STORE_MODULE_NAME_SEARCH_OPTION)) store.registerModule(STORE_MODULE_NAME_SEARCH_OPTION, storeModuleSearchOption)

    if (this.$route.params.id !== 'add') {
      const obj = {
        id: this.$route.params.id,
        serviceTypeId: this.$route.params.serviceTypeId,
      }

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/view`, obj)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          const respData = result.data.data
          this.selectedData(respData)
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    } else {
      const day = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
      for (let index = 0; index < day.length; index += 1) {
        this.workingPeriod.push({
          day: day[index],
          openTime: {
            hour: null,
            minute: null,
          },
          closeTime: {
            hour: null,
            minute: null,
          },
          openTime2: {
            hour: null,
            minute: null,
          },
          closeTime2: {
            hour: null,
            minute: null,
          },
          status: false,
        })
      }
    }
    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME_SERVICE_AREA}/get`, {
        currentPage: 0,
        pageSize: 0,
        searchQuery: '',
      })
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME_PARTNER_TEMPLATE_TIME}/get`, {
        currentPage: 0,
        pageSize: 0,
        searchQuery: '',
      })
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME_SERVICE_PACKAGE}/get`, {
        serviceType: this.$route.params.serviceType.replace('-', '_'),
        currentPage: 0,
        pageSize: 0,
        searchQuery: '',
      })
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.getSearchOption()
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    if (store.hasModule(STORE_MODULE_NAME_SERVICE_AREA)) store.unregisterModule(STORE_MODULE_NAME_SERVICE_AREA)
    if (store.hasModule(STORE_MODULE_NAME_PARTNER_TEMPLATE_TIME)) store.unregisterModule(STORE_MODULE_NAME_PARTNER_TEMPLATE_TIME)
    if (store.hasModule(STORE_MODULE_NAME_SERVICE_PACKAGE)) store.unregisterModule(STORE_MODULE_NAME_SERVICE_PACKAGE)
    if (store.hasModule(STORE_MODULE_NAME_SEARCH_OPTION)) store.unregisterModule(STORE_MODULE_NAME_SEARCH_OPTION)
  },
  setup() {
    return {
      STORE_MODULE_NAME, STORE_MODULE_NAME_SERVICE_AREA, STORE_MODULE_NAME_PARTNER_TEMPLATE_TIME, STORE_MODULE_NAME_SERVICE_PACKAGE, STORE_MODULE_NAME_SEARCH_OPTION,
    }
  },
  methods: {
    onSelectOption(position, id) {
      const obj = {
        [`${position}`]: id,
      }

      const keys = this.options.data

      keys.forEach(key => {
        // eslint-disable-next-line radix
        if (parseInt(key) > position) {
          delete this.selectedOption[key]
        }
      })

      this.selectedOption = {
        ...this.selectedOption,
        ...obj,
      }
    },
    filterPosition(position) {
      if (this.searchOption.hasOwnProperty(position)) {
        let data = this.searchOption
        if (position > 0) {
          data = data.filter(e => e.previousId !== null)
          if (this.selectedOption[position - 1]) {
            data = data.filter(e => e.previousId.toString() === this.selectedOption[position - 1].toString())
          }
        }
        data = data.filter(e => e.position.toString() === position.toString())
        return data
      }
      return []
    },
    getSearchOption() {
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME_SEARCH_OPTION}/get`, {})
        .then(result => {
          console.log('fetch Success : ', result)
          this.show = false
        }).catch(error => {
          console.log('fetchUsers Error : ', error)
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME_SEARCH_OPTION}/optionGet`, {})
        .then(result => {
          console.log('fetch Success : ', result)
          this.show = false
        }).catch(error => {
          console.log('fetchUsers Error : ', error)
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    checkStartDistance(data, index) {
      if (index > 0) {
        if (data.start !== '' && data.start !== null) {
          if (parseFloat((this.callTable[index - 1].end).toString().replace(',', '')) > parseFloat((data.start).toString().replace(',', ''))) {
            return true
          }
        }
      }
      return false
    },
    // eslint-disable-next-line no-unused-vars
    checkEndDistance(data, index) {
      if (data.start !== '' && data.start !== null && data.end !== '' && data.end !== null) {
        if (parseFloat((data.start).toString().replace(',', '')) > parseFloat((data.end).toString().replace(',', ''))) {
          return true
        } if (parseFloat((data.start).toString().replace(',', '')) === parseFloat((data.end).toString().replace(',', ''))) {
          return true
        }
      }
      return false
    },
    drawInMap(location) {
      // กดแล้วเปลี่ยน center
      const lat = location.latLng.lat()
      const lng = location.latLng.lng()
      this.partnerLocation.coordinates[0] = lng
      this.partnerLocation.coordinates[1] = lat
      this.dataCenter.lat = lat
      this.dataCenter.lng = lng
      this.$refs.mapRef.$mapPromise.then(map => {
        map.panTo({ lat, lng })
      })
    },
    setLocal(location) {
      // ลากแล้วเปลี่ยน center
      const lat = location.latLng.lat()
      const lng = location.latLng.lng()
      this.partnerLocation.coordinates[0] = lng
      this.partnerLocation.coordinates[1] = lat
      this.dataCenter.lat = lat
      this.dataCenter.lng = lng
    },
    removeCover(index) {
      this.cover.splice(index, 1)
    },
    inputCoverRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 300KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.cover.push(e.target.result)
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
          this.$refs.refInputCover.reset()
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetCover() {
      this.cover = []
    },
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 300KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
          this.$refs.refInputImage.reset()
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.image = ''
    },
    templateTime(data) {
      if (data) {
        this.workingPeriod = data
      } else {
        const day = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
        for (let index = 0; index < day.length; index += 1) {
          this.workingPeriod.push({
            day: day[index],
            openTime: {
              hour: null,
              minute: null,
            },
            closeTime: {
              hour: null,
              minute: null,
            },
            openTime2: {
              hour: null,
              minute: null,
            },
            closeTime2: {
              hour: null,
              minute: null,
            },
            status: false,
          })
        }
      }
    },
    timeFomat(n) {
      if (n !== null) {
        if (n.toString().length === 1) {
          return `0${n.toString()}`
        }
      }
      return n
    },
    selectedData(data) {
      if (data.servicePackageId) {
        this.servicePackageId = data.servicePackageId
      }
      if (data.serviceAreaId) {
        this.serviceAreaId = data.serviceAreaId
      }
      if (data.image) {
        this.image = data.image
      }
      if (data.cover) {
        this.cover = data.cover
      }
      if (data.firstName) {
        this.firstName = data.firstName
      }
      if (data.lastName) {
        this.lastName = data.lastName
      }
      if (data.systemOptions) {
        this.systemOptions = data.systemOptions
      }
      if (data.workBehavior) {
        this.workBehavior = data.workBehavior
      }
      if (data.workingPeriod) {
        if (data.workingPeriod.length === 7) {
          this.workingPeriod = data.workingPeriod
        } else {
          const day = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
          for (let index = 0; index < day.length; index += 1) {
            this.workingPeriod.push({
              day: day[index],
              openTime: {
                hour: null,
                minute: null,
              },
              closeTime: {
                hour: null,
                minute: null,
              },
              openTime2: {
                hour: null,
                minute: null,
              },
              closeTime2: {
                hour: null,
                minute: null,
              },
              status: false,
            })
          }
        }
      }
      if (data.callTableStatus) {
        this.callTableStatus = data.callTableStatus
      }
      if (data.callTable) {
        if (data.callTable.length > 0) {
          let { callTable } = data
          // eslint-disable-next-line no-multi-assign
          callTable = callTable.map((p, index) => ({
            start: index === 0 ? '0' : p.start,
            end: p.end,
            price: p.price,
          }))
          this.callTable = callTable
        } else {
          this.callTable = [
            {
              start: '0', // ระยะทางเริ่มต้น
              end: '', // ระยะสิ้นสุด
              price: '', // ราคา
            },
          ]
        }
      }
      if (data.partnerLocation.coordinates.length === 2) {
        const lat = parseFloat(data.partnerLocation.coordinates[1])
        const lng = parseFloat(data.partnerLocation.coordinates[0])
        this.partnerLocation.coordinates[0] = lng
        this.partnerLocation.coordinates[1] = lat
        this.dataCenter.lat = lat
        this.dataCenter.lng = lng
        this.$refs.mapRef.$mapPromise.then(map => {
          map.panTo({ lat, lng })
        })
      }
      if (data.gender) {
        this.gender = data.gender
      }
      if (data.licensePlate) {
        this.licensePlate = data.licensePlate
      }
    },
    deleteCallTable(index) {
      this.callTable.splice(index, 1)
      if (this.callTable.length > 0) {
        if (this.callTable[0].start !== '0') {
          this.callTable[0].start = '0'
        }
      }
    },
    addCallTable(index) {
      this.callTable.push({
        start: `'${this.callTable[index].end}'`, // ระยะทางเริ่มต้น
        end: '', // ระยะสิ้นสุด
        price: '', // ราคา
      })
    },
    validationForm() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to save')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.$refs.formData.validate().then(success => {
              if (success) {
                const obj = {
                  id: this.$route.params.id === 'add' ? null : this.$route.params.id,
                  serviceTypeId: this.$route.params.serviceTypeId,
                  servicePackageId: this.servicePackageId,
                  serviceAreaId: this.serviceAreaId,
                  image: this.image,
                  cover: this.cover,
                  firstName: this.firstName,
                  lastName: this.lastName,
                  systemOptions: this.systemOptions,
                  workBehavior: this.workBehavior,
                  workingPeriod: this.workingPeriod,
                  callTableStatus: this.callTableStatus,
                  callTable: this.callTable.filter(p => p.start !== '' && p.start !== null && p.end !== '' && p.end !== null && p.price !== '' && p.price !== null).map(p => ({
                    start: p.start.replace(',', ''),
                    end: p.end.replace(',', ''),
                    price: p.price.replace(',', ''),
                  })),
                  partnerLocation: this.partnerLocation,
                  gender: this.gender,
                  licensePlate: this.licensePlate,
                  selectedOption: this.selectedOption,
                  selectedListOption: this.selectedListOption,
                }
                if (obj.id !== null) {
                  this.show = true

                  store
                    .dispatch(`${STORE_MODULE_NAME}/update`, obj)
                    .then(result => {
                      console.log('fetch Success : ', result)
                      this.show = false
                      this.$router.push({ path: `/hero/partner/type/${this.$route.params.serviceTypeId}/${this.$route.params.serviceType}` })
                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Success'),
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: this.$t('Success'),
                        },
                      })
                    }).catch(error => {
                      console.log('fetchUsers Error : ', error)
                      this.show = false

                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Error'),
                          icon: 'ErrorIcon',
                          variant: 'danger',
                          text: this.$t(error.response.data.message),
                        },
                      })
                    })
                } else {
                  this.show = true

                  store
                    .dispatch(`${STORE_MODULE_NAME}/add`, obj)
                    .then(result => {
                      console.log('fetch Success : ', result)
                      this.show = false

                      this.$router.push({ path: `/hero/partner/type/${this.$route.params.serviceTypeId}/${this.$route.params.serviceType}` })

                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Success'),
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: this.$t('Success'),
                        },
                      })
                    }).catch(error => {
                      console.log('fetchUsers Error : ', error)
                      this.show = false

                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Error'),
                          icon: 'ErrorIcon',
                          variant: 'danger',
                          text: this.$t(error.response.data.message),
                        },
                      })
                    })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t('Error Validator'),
                  },
                })
              }
            })
          }
        })
    },
    cancel() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to cancel')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.$router.push({ path: `/hero/partner/package/${this.$route.params.serviceTypeId}` })
          }
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.btn-remove {
  position: absolute;
  top: 20%;
  left: 30%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: red;
  color: white;
  font-size: 12px;
  padding: 12px 16px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  text-align: center;
}
.imgs-show {
  // width: 100%;
  height: 100px;
}
</style>
